import request from '@/utils/request'
export function listPushSet (data) {
  return request({
    url: '/syspush/listSyspush',
    method: 'POST',
    data
  })
}
export function addSyspush (data) {
  return request({
    url: '/syspush/addSyspush',
    method: 'POST',
    data
  })
}
export function updateSyspushStatus (data) {
  return request({
    url: '/syspush/updateSyspushStatus',
    method: 'POST',
    data
  })
}
export function querySyspush (data) {
  return request({
    url: '/syspush/querySyspush',
    method: 'POST',
    data
  })
}
export function updateSyspush (data) {
  return request({
    url: '/syspush/updateSyspush',
    method: 'POST',
    data
  })
}
export function deleteSyspush (data) {
  return request({
    url: '/syspush/deleteSyspush',
    method: 'POST',
    data
  })
}
export function listSyspushCount (data) {
  return request({
    url: '/syspush/listSyspushCount',
    method: 'POST',
    data
  })
}
export function addSystemInformation (data) {
  return request({
    url: '/syspush/addSystemInformation',
    method: 'POST',
    data
  })
}
export function updateSystemInformation (data) {
  return request({
    url: '/syspush/updateSystemInformation',
    method: 'POST',
    data
  })
}
export function selectSystemInformation (data) {
  return request({
    url: '/syspush/selectSystemInformation',
    method: 'POST',
    data
  })
}
export function dlectSystemInformation (data) {
  return request({
    url: '/syspush/dlectSystemInformation',
    method: 'POST',
    data
  })
}
export function selectAllUser (data) {
  return request({
    url: '/syspush/selectAllUser',
    method: 'POST',
    data
  })
}
export function selectSystemInformationById (data) {
  return request({
    url: '/syspush/selectSystemInformationById',
    method: 'POST',
    data
  })
}
export function selectCountSystemInformation (data) {
  return request({
    url: '/syspush/selectCountSystemInformation',
    method: 'POST',
    data
  })
}
export function updateSystemInformationStatus (data) {
  return request({
    url: '/syspush/updateSystemInformationStatus',
    method: 'POST',
    data
  })
}
