<template>
  <section class="form-push-wrap">
    <el-form ref="form" :model="form" label-width="80px" :rules="rules" v-loading="loading">
      <el-form-item label="通知标题" prop="messageTitle">
        <el-input v-model="form.messageTitle"></el-input>
      </el-form-item>
      <el-form-item label="通知内容" prop="messageContent">
        <el-input
          type="textarea"
          :autosize="{ minRows: 2, maxRows: 4}"
          placeholder="请输入内容"
          v-model="form.message">
        </el-input>
      </el-form-item>
      <el-form-item label="通知人">
        <el-select v-model="form.users" multiple placeholder="请选择" @change="$forceUpdate()">
          <el-option
            v-for="item in options"
            :key="item.id"
            :label="item.nickname"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
<!--        <el-select v-model="form.messageContact" >-->
<!--          <el-option label="全部" value="0"></el-option>-->
<!--        </el-select>-->
<!--      </el-form-item>-->
      <el-form-item>
        <el-button type="primary" @click="onSubmit">完成  </el-button>
        <el-button size="medium" @click="$router.go(-1)">返回</el-button>
      </el-form-item>
    </el-form>
  </section>
</template>

<script>
import { showMonitorSubject } from '@/api/settings/MonitorSubject'
import { listEmailUsers, listWXUsers } from '@/api/settings/warning'
import { selectAllUser, addSystemInformation, selectSystemInformationById , updateSystemInformation, addSyspush, querySyspush, updateSyspush } from '@/api/settings/push'
import eventHub from '@/utils/eventHub'
export default {
  data () {
    return {
      receiveParam: '',
      diyEmailStatus: 0,
      emailList: [],
      wxList: [],
      options: [],
      loading: false,
      form: {
        id: '',
        messageTitle: '',

        message: '',
        users: [],
        userId: ''
      },
      msList: [
        {
          id: 1,
          name: 'IT'
        },
        {
          id: 2,
          name: 'CANYIN'
        }
      ],
      rules: {
        messageTitle: [
          { required: true, message: '标题不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    changeWX () {
      this.$forceUpdate()
    },
    changeEmail (selVal) {
      this.$forceUpdate()
      if (selVal.indexOf(-1) !== -1) {
        this.diyEmailStatus = 1
      } else {
        this.diyEmailStatus = 0
        this.form.diyEmailList = ''
      }
    },
    selectChange () {
      this.$forceUpdate()
    },
    async listAllEmail () {
      const res = await listEmailUsers()
      this.emailList = res.data
    },
    async listAllWX () {
      const res = await listWXUsers()
      this.wxList = res.data
    },
    async listAllMS () {
      const res = await showMonitorSubject(this.form)
      console.log(res)
      this.msList = res.data
    },
    async addPush () {
      // if (this.form.manner === '0') {
      //   this.receiveParam = ''
      //   for (var i = 0; i < this.form.selectedEmail.length; i++) {
      //     if (this.form.selectedEmail[i] !== -1) {
      //       if (this.diyEmailStatus === 1) {
      //         this.receiveParam += this.form.selectedEmail[i] + '、'
      //       } else {
      //         if (i < this.form.selectedEmail.length - 1) {
      //           this.receiveParam += this.form.selectedEmail[i] + '、'
      //         } else {
      //           this.receiveParam += this.form.selectedEmail[i]
      //         }
      //       }
      //     }
      //   }
      //   this.receiveParam += this.form.diyEmailList
      //   this.form.receive = this.receiveParam
      // } else if (this.form.manner === '1') {
      //   this.receiveParam = ''
      //   for (var j = 0; j < this.form.selectedWX.length; j++) {
      //     if (j < this.form.selectedWX.length - 1) {
      //       this.receiveParam += this.form.selectedWX[j] + '、'
      //     } else {
      //       this.receiveParam += this.form.selectedWX[j]
      //     }
      //   }
      //   this.form.receive = this.receiveParam
      // }
      // const res = await addSyspush(this.form)
      console.log(this.form)
      var userIdVar = ''
      for (var i = 0; i < this.form.users.length; i++) {
        if (i === this.form.users.length - 1) {
          userIdVar += this.form.users[i]
        } else {
          userIdVar += this.form.users[i] + '、'
        }
      }
      this.form.userId = userIdVar
      const res = await addSystemInformation(this.form)
      if (res.code === 0) {
        this.$message.success('添加成功')
        this.$router.push('/settings/push')
      } else {
        this.$message.error('添加失败')
      }
    },
    onSubmit () {
      // console.log(this.form)
      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          if (this.$route.meta.type === 'edit') {
            this.updatePushSet()
          } else {
            this.addPush()
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    async showPushSet () {
      this.loading = true
      const res = await selectSystemInformationById({ id: this.$route.params.id })
      if (res.code === 0) {
        this.form = res.data
        if(res.data.userId){
          var userList = res.data.userId.split('、')
          console.log(userList)
          this.form.users = userList
        }

        console.log(res.data)
      }
      // const res = await querySyspush({ syspushId: this.$route.params.id })
      // if (res.code === 0) {
      //   this.form = res.data
      //   this.form.interval += ''
      //   this.form.messageContent += ''
      //   this.form.manner += ''
      //   this.form.count += ''
      //   this.form.id = this.$route.params.id
      //   if (this.form.manner === '1') {
      //     this.form.selectedWX = res.data.receive.split('、')
      //   } else if (this.form.manner === '0') {
      //     var test = res.data.receive.split('、')
      //     var selectedEmailList = []
      //     var diyEmailList = []
      //     console.log(test)
      //     console.log(this.emailList)
      //     for (var i = 0; i < test.length; i++) {
      //       for (var j = 0; j < this.emailList.length; j++) {
      //         if (test[i] === this.emailList[j].value) {
      //           selectedEmailList.push(test[i])
      //           break
      //         } else {
      //           if (j === this.emailList.length - 1) {
      //             if (selectedEmailList.indexOf(-1) === -1) {
      //               selectedEmailList.push(-1)
      //             }
      //             diyEmailList.push(test[i])
      //             this.diyEmailStatus = 1
      //           }
      //         }
      //       }
      //     }
      //     this.form.diyEmailList = ''
      //     for (var z = 0; z < diyEmailList.length; z++) {
      //       console.log(diyEmailList[z])
      //       this.form.diyEmailList += diyEmailList[z]
      //     }
      //     this.form.selectedEmail = selectedEmailList
      //   }
      // }
      this.loading = false
    },
    async updatePushSet () {
      // if (this.form.manner === '0') {
      //   this.receiveParam = ''
      //   for (var i = 0; i < this.form.selectedEmail.length; i++) {
      //     if (this.form.selectedEmail[i] !== -1) {
      //       if (this.diyEmailStatus === 1) {
      //         this.receiveParam += this.form.selectedEmail[i] + '、'
      //       } else {
      //         if (i < this.form.selectedEmail.length - 1) {
      //           this.receiveParam += this.form.selectedEmail[i] + '、'
      //         } else {
      //           this.receiveParam += this.form.selectedEmail[i]
      //         }
      //       }
      //     }
      //   }
      //   this.receiveParam += this.form.diyEmailList
      //   this.form.receive = this.receiveParam
      // } else if (this.form.manner === '1') {
      //   this.receiveParam = ''
      //   for (var j = 0; j < this.form.selectedWX.length; j++) {
      //     if (j < this.form.selectedWX.length - 1) {
      //       this.receiveParam += this.form.selectedWX[j] + '、'
      //     } else {
      //       this.receiveParam += this.form.selectedWX[j]
      //     }
      //   }
      //   this.form.receive = this.receiveParam
      // }
      var userIdVar = ''
      for (var i = 0; i < this.form.users.length; i++) {
        if (i === this.form.users.length - 1) {
          userIdVar += this.form.users[i]
        } else {
          userIdVar += this.form.users[i] + '、'
        }
      }
      this.form.userId = userIdVar
      const res = await updateSystemInformation(this.form)
      if (res.code === 0) {
        this.$message.success('修改成功')
        this.$router.push('/settings/push')
      } else {
        this.$message.error('修改失败')
      }
    },
    async viewselectAllUser () {
      const res = await selectAllUser()
      this.options = res.data
      console.log(res)
    }
  },
  mounted () {
    this.viewselectAllUser()
    // this.listAllMS()
    // this.listAllEmail()
    // this.listAllWX()
    if (this.$route.meta.type === 'edit') {
      this.showPushSet()
      eventHub.$emit('msg', '修改系统规则')
    } else if (this.$route.meta.type === 'add') {
      eventHub.$emit('msg', '创建系统规则')
    }
  }
}
</script>

<style lang="scss">
.form-push-wrap {
  width: 100%;
  // min-height: 100%;
  background: #fff;
  border-radius: 2px;
  box-shadow:0px 1px 13px 0px rgba(0, 0, 0, 0.1);
  padding: 20px 20px;
  position: relative;
  .title {
    font-size: 16px;
    color: #303133;
  }
  .el-form {
    margin-top: 30px;
    .el-form-item {
      .el-input {
        width: 425px;
      }
    }
  }
}
</style>
